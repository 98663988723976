import { useState } from "react";
import Logo from "../../assets/images/logo.png";
import { Close, Menu } from "@mui/icons-material";

function Header() {
  const [open, setOpen] = useState(false);
  return (
    <header className="header">
      <section className="fit-content flex justify-space-between align-center">
        <a className="header-logo" href="/">
          <img src={Logo} alt="logo" />
        </a>
        <div>
          <div className="menu-icon">
            <Menu onClick={() => setOpen(true)} />
          </div>
          <nav className={`header-nav-links ${open ? "open" : "close"}`}>
            {open && (
              <>
                <div className="close-icon">
                  <Close fontSize="inherit" onClick={() => setOpen(false)} />
                </div>
                <ul className="flex" onClick={() => setOpen(false)}>
                  <li>
                    <a href="/">About Us</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/careers">Careers</a>
                  </li>
                  <li>
                    <a href="/technologies">Technologies</a>
                  </li>
                  <li>
                    <a href="/clients">Clients</a>
                  </li>
                  <li>
                    <a href="#contact">Contact Us</a>
                  </li>
                </ul>
              </>
            )}
            <ul className="flex web-links">
              <li>
                <a href="/">About Us</a>
              </li>
              <li>
                <a href="/services">Services</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="/technologies">Technologies</a>
              </li>
              <li>
                <a href="/clients">Clients</a>
              </li>
              <li>
                <a href="#contact">Contact Us</a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </header>
  );
}

export default Header;

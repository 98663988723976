import { Services } from "..";
import StaffingImage from "../../assets/images/services/stf-inner.jpeg";

function EnterprisePage() {
  const content = (
    <>
      <p>
        We help internal infrastructure and assets to run specific enterprise software, meet security and privacy
        requirements, adhere to industry regulations, manage and control costs, or maintain complete control over all
        aspects of their environments. On hybrid environments running complex enterprise software platforms like ERP
        systems continue to play a vital role in the operations of many industries.
      </p>
    </>
  );
  return <Services image={StaffingImage} title="ENTERPRISE SOLUTIONS" content={content}></Services>;
}

export default EnterprisePage;

import { Services } from "..";
import TrainingImage from "../../assets/images/services/stf-inner.jpeg";

function ConsultingPage() {
  const content = (
    <>
      <h3>WEB APPLICATION</h3>
      <p>
        With obvious draw-back of off-the-shelf applications varying from difficulty in customizing to requiring a lot
        of investment in terms of capital and manpower to suit one's requirements, enterprises have risen to the
        occasion to opt for a custom-built application that is closely aligned to their business processes.
      </p>
      <p></p>
      <p>
        While off-the-shelf applications cater to an enormous audience with general necessities, bespoke applications
        are selected by enterprises with specific business requirements. With the incremental standard of building and
        executing applications where small business methods are chosen to automate before entirely automating the
        business processes, risk failure is greatly reduced.
      </p>
      <p>
        At R7 IT solutions we are determined to create robust applications that contribute your firm with an edge to
        attain business efficiency and allow you to equalize in this highly competitive and elevated performance-driven
        corporate world.
      </p>
      <ul>
        <li>Custom Application Development</li>
        <li>Application Migration and Porting</li>
        <li>Testing and Validation</li>
        <li>Application Maintenance</li>
      </ul>
      <p>
        R7 IT solutions embraces a proactive strategy to custom application support and maintenance, by concentrating on
        long-term functionality, stability, and preventive maintenance to bypass any implementation or post-deployment
        issues.
      </p>
      <h3>MOBILE APPLITIONS</h3>
      <p>
        Mobile apps are influential drivers of traffic, increasing advertising, sales, and growth. To make a special
        position in the market organizations must have an efficient mobile app to provide the best to their customers.R7
        IT solutions supplies effective mobile app development services with a primary priority on providing competent,
        dynamic and affordable, mobile apps with on-time delivery.
      </p>
      <h3>ANDROID</h3>
      <p>
        The market is embarked by android devices. The most reasonable method for organizations to reach directly to
        every customer is through an android device. R7 IT solutions is a master android device developer with abundant
        experience. Our developed apps have brought the most incredible satisfaction to the clients.
      </p>
      <h3>I-PAD / I-PHONE</h3>
      <p>
        Although android has taken over the market iPhone / ios is always the most bug-free and reliable platform for
        both clients and developers. The iPad/iPhone has its distinctive position in the market which is never going to
        fade. R7 IT solutions team has great professionalism in developing apps for this platform.
      </p>
      <h3>WINDOWS</h3>
      <p>
        Although windows are gradually diminishing it shares a considerable client base in the market but still the
        significance of the application is not declining we, R7 IT solutions expert team can provide you best possible
        window app development at the most affordable price in the market.
      </p>
      <h3>CROSS-PLATFORM DEVELOPMENT</h3>
      <p>
        Many organizations demand cross-platform app products to have an exceptional layout and configuration that runs
        on all platforms moreover cross-platform applications' most persuasive factor is reduced development
        expenditure. Depending upon different business conditions sometimes cross-platforms are more beneficial than
        native. R7 IT solutions team of experts provides the considerable featured cross-platform app with splendid
        efficiency. The team of experts will enforce every prerequisite of the customer and bring out the best possible
        development to supply the finest deliverable.
      </p>
      <h3>TESTING</h3>
      <p>
        For 8 years in software testing services, R7 IT solutions has built testing excellence in healthcare,
        manufacturing, retail, wholesale, logistics, and different industries. ERP, CRM, Business process automation
        software, Supply chain management software, Ecommerce, Financial management software, Product lifecycle
        management software, Human capital management software.
      </p>
      <p>
        R7 IT solutions professionals intently collaborate with the development teams for effective and efficient
        risk-based testing concentrated on innovation challenges. We are experienced and skilled with testing specifics
        of structures with the following innovative, progressive techs and architectures:
      </p>
      <p>Microservices testing, Big data testing, AR testing, IoT testing, Blockchain testing.</p>
      <h4>Testing Types We Perform:</h4>
      <h4>1. Essential Testing Types:</h4>
      <p>
        Functional testing, Integration testing, Compatibility testing, Accessibility testing, Regression testing,
        Exploratory testing, Testing of newly introduced software functions
      </p>
      <h4>2.Specific Testing Types</h4>
      <p>Performance testing, Security testing, Usability testing</p>
      <h4>3.Test Automation</h4>
      <p>
        R7 IT solutions sticks to the proprietary Integrated Manual and Automated Testing (IMAAT) method to optimize the
        testing time period. Our test automation leads explore automated testing feasibility and estimate its ROI for
        each testing project. If it’s beneficial enough, we design reusable test scripts for automated testing at API
        and UI levels and introduce test automation within three weeks.
      </p>
    </>
  );
  return <Services image={TrainingImage} title="SOFRWARE DEVELOPMENT" content={content}></Services>;
}

export default ConsultingPage;

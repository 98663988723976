import { PageBanner, Page } from "..";
import AboutImage from "../../assets/images/aboutus.png";
import BannerImage from "../../assets/images/office.jpeg";
import Artura from "../../assets/images/clients/artura.jpeg";
import Nyc from "../../assets/images/clients/nyc.jpeg";
import Wolters from "../../assets/images/clients/wolters.jpeg";
import { TipsAndUpdates, Fax, Language, School } from "@mui/icons-material";

import StaffingImage from "../../assets/images/services/staffing.jpeg";
import TrainingImage from "../../assets/images/services/training.jpeg";
import ConsultingImage from "../../assets/images/services/consulting.jpeg";
import DevelopmentImage from "../../assets/images/services/development.jpeg";
import EnterpriseImage from "../../assets/images/services/enterprice.webp";

function About() {
  return (
    <Page>
      <section>
        <PageBanner title="About Us" image={BannerImage} />
        <div className="page-aboutus">
          <div className="fit-content flex content-wrapper">
            <div className="content">
              <h1 className="title">Welcome To R7 IT Solutions</h1>
              <div className="tagline">Fastest Growing Innovative Managed Services, Strategic IT Consulting</div>
              <p>
                R7 IT Solutions delivers managed information technology services, strategic software consulting and
                innovative technical solutions that fuel your growth and business results. We partner with you to engage
                your business objectives with our IT expertise, empowering your company execute business strategy and
                scale your business success effectively and efficiently through our best practices.
              </p>

              <p>
                Where many software consulting and IT service providers are focused on “the transaction," we believe in
                establishing and maintaining long-term partnerships so that we can meet your strategic needs and enable
                your organization to glean greater business value over time.
              </p>

              <p>
                Our leadership and talent team has very long standing IT exposure serving global clientele, we offer
                dedicated presence, deep networks, as well as international reach.
              </p>

              <p>
                And with a World Class Customer Care and Technical Support Program, you can be sure that our clients
                receive the support and care they need so that they can be assured of our professional quality. R7 IT
                Solutions is privately held and have business operations in India and USA.
              </p>
            </div>
            <div className="banner-image flex align-center">
              <img alt="About" src={AboutImage} />
            </div>
          </div>
          <div className="working-process">
            <div className="fit-content ">
              <div className="flex justify-center">
                <h1 className="title">Services Offerings</h1>
              </div>
              <p className="description">
                We at R7 IT solutions are specific to the handling of Staffing, Training, Consulting, IT but also
                maintain the same all the time with the best supervision.
              </p>
              <div className="flex justify-space-around cards">
                <div className="card-wrapper">
                  <div className="process-card">
                    <div className="process-card__image_wrapper flex justify-center align-center">
                      <TipsAndUpdates fontSize="inherit" />
                    </div>
                    <div className="card-content">
                      <h3 className="text-center">STAFFING</h3>
                      <p className="text-center">Discovering the appropriate talent at the appropriate time.</p>
                    </div>
                  </div>
                </div>

                <div className="card-wrapper">
                  <div className="process-card">
                    <div className="process-card__image_wrapper flex justify-center align-center">
                      <School fontSize="inherit" />
                    </div>
                    <div className="card-content">
                      <h3 className="text-center">TRAINING</h3>
                      <p className="text-center">
                        Training promotes to acquire the skills and ready for all the challenges.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card-wrapper">
                  <div className="process-card">
                    <div className="process-card__image_wrapper flex justify-center align-center">
                      <Fax fontSize="inherit" />
                    </div>
                    <div className="card-content">
                      <h3 className="text-center">CONSULTING</h3>
                      <p className="text-center">Complicated business challenges and determined goals.</p>
                    </div>
                  </div>
                </div>
                <div className="card-wrapper">
                  <div className="process-card">
                    <div className="process-card__image_wrapper flex justify-center align-center">
                      <Language fontSize="inherit" />
                    </div>
                    <div className="card-content">
                      <h3 className="text-center">IT</h3>
                      <p className="text-center">Innovation and technology are accelerating the world forward.</p>
                    </div>
                  </div>
                </div>
                <div className="card-wrapper">
                  <div className="process-card">
                    <div className="process-card__image_wrapper flex justify-center align-center">
                      <Language fontSize="inherit" />
                    </div>
                    <div className="card-content">
                      <h3 className="text-center">Enterprise Solutions</h3>
                      <p className="text-center">We help internal infrastructure to run specific enterprise software</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="services-offered fit-content">
            <div class="section-title text-center">
              <h2>Services Offerings</h2>
            </div>

            <p className="page-content text-center">
              R7 IT Solutions offers comprehensive staffing, recruiting, consulting, IT services that you simply will
              rely on to fill your most crucial desires. We tend to believe business challenges square measure resolved
              through individuals. That’s why we tend to work to know company norms, processes, associated team dynamics
              and believe that cultural work is crucial to the success of an engagement.
            </p>

            <div className="services-offered-cards flex justify-center">
              <div className="services-offered-card">
                <img src={StaffingImage} alt="Staffing" />
                <div className="overlay">
                  <a href="/staffing">Staffing</a>
                </div>
              </div>

              <div className="services-offered-card">
                <img src={TrainingImage} alt="Training" />
                <div className="overlay">
                  <a href="/training">Training</a>
                </div>
              </div>

              <div className="services-offered-card">
                <img src={ConsultingImage} alt="Consulting" />
                <div className="overlay">
                  <a href="/consulting">Consulting</a>
                </div>
              </div>

              <div className="services-offered-card">
                <img src={DevelopmentImage} alt="Software development" />
                <div className="overlay">
                  <a href="/development">Software development</a>
                </div>
              </div>

              <div className="services-offered-card">
                <img src={EnterpriseImage} alt="Enterprise solutions" />
                <div className="overlay">
                  <a href="/enterprise">Enterprise solutions</a>
                </div>
              </div>
            </div>
          </div>

          <div className="our_clients">
            <div className="fit-content">
              <div class="section-title">
                <h2>Our Clients</h2>
              </div>

              <div className="client-items flex">
                <div class="client-item">
                  <div class="single-logo">
                    <img src={Artura} alt="Partner" />
                  </div>
                </div>
                <div class="client-item">
                  <div class="single-logo">
                    <img src={Nyc} alt="Partner" />
                  </div>
                </div>
                <div class="client-item">
                  <div class="single-logo">
                    <img src={Wolters} alt="Partner" />
                  </div>
                </div>
                <div class="client-item">
                  <div class="single-logo">
                    <img src={Artura} alt="Partner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}

export default About;

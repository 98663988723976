import { Email, LocalPhone, LocationOn, Twitter, Instagram, LinkedIn, FacebookRounded } from "@mui/icons-material";

function Footer() {
  return (
    <footer id="contact" className="footer">
      <section className="fit-content">
        <div className="footer-top">
          <div className="footer-contact-info">
            <h3 className="footer-title">Contact Us</h3>
            <div className="flex contact-item">
              <LocalPhone className="footer-icon" />
              <a href="tel:+1 (631) 645-4439">+1 (631) 645-4439</a>
            </div>

            <div className="flex contact-item">
              <Email className="footer-icon" />
              <a href="mailto: hr@r7itsolutions.com">hr@r7itsolutions.com</a>
            </div>

            <div className="flex contact-item">
              <LocationOn className="footer-icon" />
              <address>
                <p>9903 Max Ln, Frisco, </p>
                <p>TX 75035, United States</p>
              </address>
            </div>
          </div>
          <div className="footer-navigation-links">
            <h3 className="footer-title">Navigation</h3>
            <ul>
              <li>
                <a href="/">About Us</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="/technologies">Technologies</a>
              </li>
              <li>
                <a href="/clients">Clients</a>
              </li>
            </ul>
          </div>
          <div className="footer-social">
            <h3 className="footer-title">Follow us</h3>
            <div className="flex">
              <a href="/" target="_blank" className="social-icon">
                <FacebookRounded />
              </a>
              <a href="/" target="_blank" className="social-icon">
                <Twitter />
              </a>
              <a href="/" target="_blank" className="social-icon">
                <LinkedIn />
              </a>
              <a href="/" target="_blank" className="social-icon">
                <Instagram />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">© 2023 All Rights Reserved. Powered by R7 IT Solutions</div>
      </section>
    </footer>
  );
}

export default Footer;

import { Services } from "..";
import TrainingImage from "../../assets/images/services/consult-inner.jpeg";

function DevelopmentPage() {
  const content = (
    <>
      <p>
        Use the expertise and in-depth tech knowledge of the finest minds at R7 IT solutions to develop a comprehensive
        IT procedure for a digital and technological modification of your organization that goes in line with your
        enterprise objectives.
      </p>

      <h3>OUR IT CONSULTING SERVICES</h3>
      <p>
        R7 IT solutions offers IT consulting services that will assist you to enhance your software architecture,
        develop a tech-driven digital strategy, and improve procedures by optimizing your software portfolio. Our
        software creators will conclude your digital modification journey through detailed planning and effective
        implementation of the outlined IT strategy.
      </p>
      <h3>ENTERPRISE ARCHITECTURE ADVISORY</h3>
      <p>
        Our Enterprise Architecture professionals can help you make the transition from outdated and unproductive IT
        delivery systems and architectures to a cloud-based infrastructure that facilitates delivery and allows for the
        execution of new technologies into your software strategy.
      </p>
      <h3>IT STRATEGY CONSULTING & NEW TECH</h3>
      <p>
        There is a need to confirm the consistent delivery of high-quality IT products and services for your enterprise
        workflow and your clients. You also must enforce new technologies to remain competitive and satisfy the demands
        of current users. Our IT solutions consulting will support your strike the balance between these two purposes,
        guaranteeing they don’t conflict with one another.
      </p>
      <h3>SOFTWARE PORTFOLIO CONSULTING</h3>
      <p>
        Our IT consulting advisors will perform a deep examination of how your industry and employees use your current
        enterprise software, as well as of your enterprise mobile strategy. Then, they will provide recommendations for
        the needed changes, recent developments, and integration of third-party solutions to extract the existing
        roadblocks.
      </p>
      <h3>Mechanical Engineering & Drawing Consulting</h3>
      <ul>
        <li>2D Drawings to 3D drawing conversion</li>
        <li>Start to finish (from concept - model -- analysis - drawing - prototype - testing - release)</li>
        <li>
          Finite Element Analysis Services for wide range of Industries including Heavy Engineering, Automotive,
          Aerospace, Oil&Gas and Turbo Machinery
        </li>
        <li>NPI projects (New Product Innovation)</li>
      </ul>
    </>
  );
  return <Services image={TrainingImage} title="CONSULTING" content={content}></Services>;
}

export default DevelopmentPage;

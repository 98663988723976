import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  About,
  Clients,
  Jobs,
  Technologies,
  StaffingPage,
  TrainingPage,
  ConsultingPage,
  DevelopmentPage,
  EnterprisePage,
  ServicesPage,
} from "./components";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="services" element={<ServicesPage />} />
        <Route path="careers" element={<Jobs />} />
        <Route path="technologies" element={<Technologies />} />
        <Route path="clients" element={<Clients />} />
        <Route path="staffing" element={<StaffingPage />} />
        <Route path="training" element={<TrainingPage />} />
        <Route path="consulting" element={<ConsultingPage />} />
        <Route path="development" element={<DevelopmentPage />} />
        <Route path="enterprise" element={<EnterprisePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { Page, ServicesMenu, PageBanner } from "..";
import BannerImage from "../../assets/images/office.jpeg";

function Services(props) {
  const { title, content, image } = props;
  return (
    <Page>
      <PageBanner image={BannerImage} title={title} />
      <section className="services-page fit-content">
        <div className="services-nav">
          <ServicesMenu selected={title} />
        </div>
        <div className="services-content">
          {image && <img src={image} alt={image} />}
          <h2>{title}</h2>
          <div className="content">{content}</div>
        </div>
      </section>
    </Page>
  );
}

export default Services;

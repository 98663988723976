import { Services } from "..";
import TrainingImage from "../../assets/images/services/train-inner.jpeg";

function StaffingPage() {
  const content = (
    <>
      <p>
        R7 IT solutions is established to provide interactive learning of essential IT-related courses. We provide
        training by Real-Time Professionals.
      </p>

      <p>
        We are convinced that we can leverage these technologies to help individuals add more value to their careers and
        allow us to synergistically grow together. We meet student needs in training and job-oriented requirements on IT
        training.
      </p>

      <h4>The advantages of environmental training are numerous and include:</h4>
      <ul>
        <li>Increasing employee retention and productivity.</li>
        <li>Providing a consistent and accurate explanation of organizational environmental strategy to customers.</li>
        <li>
          Creating capacity for auditing and monitoring in the house to avoid the need for consultants for compliance
          purposes.
        </li>
        <li>Forming part of an organizational environmental strategy that can be promoted to clients.</li>
        <li>Building employee capacity in specific fields.</li>
      </ul>

      <p>
        Classroom training Provide personal attention to every person and immediate advice to students. Strengthen
        learning from class discussions and exercises. Classroom labs can allow for more time.
      </p>
      <h4>Online training is useful for several reasons:</h4>
      <ul>
        <li>
          Participants can attend a course at any time from anywhere and update themselves with course material at any
          point down the track.
        </li>
        <li>Training is self-paced to suit the learning style of the student.</li>
        <li>
          Online training is cheaper than traditional classroom-based teaching making it more accessible and affordable.
        </li>
        <li>
          Online training modules are developed very quickly to allow the fast rollout of new concepts, changes in
          Government legislation, and updated knowledge bases.
        </li>
        <li>
          Online exercise automates the reporting and marking procedure and streamlines workflows for learning managers.
          Reports can be tailored to suit organizational-specific requirements and are generated automatically.
        </li>
      </ul>

      <p>
        The courses are developed in a method that will give a whole understanding and hands-on experience of the
        technologies.
      </p>
    </>
  );
  return <Services image={TrainingImage} title="TRAINING" content={content}></Services>;
}

export default StaffingPage;

import { PageBanner, Page } from "..";
import Banner from "../../assets/images/office.jpeg";

function Jobs() {
  return (
    <Page>
      <section className="page-careers">
        <PageBanner title="Careers" image={Banner} />
        <div className="fit-content">
          <div className="content">
            <div className="section-title">
              <h2>Work with us</h2>
            </div>
            <p className="description">
              R7 IT solutions owes its longstanding success to our exceptionally skilled professional staff. We are
              excited to connect with ambitious and driven technology professionals who are passionate about helping our
              clientsÃ¢â‚¬â„¢ businesses perform at optimal levels. We are looking for a combination of fresh thinking
              college graduates and those with years of vital industry experience. At R7 IT solutions, we appreciate the
              synergy that is created by placing bright individuals with varied experiences and abilities together. R7
              IT solutions is an equal opportunity employer. We look forward to discussing the future with you.
            </p>
          </div>
        </div>
      </section>
    </Page>
  );
}

export default Jobs;

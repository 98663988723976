function PageBanner({ title, image }) {
  return (
    <section className="page-banner" style={{ backgroundImage: `url(${image})` }}>
      <div className="page-banner-content">
        <h1>{title}</h1>
      </div>
    </section>
  );
}

export default PageBanner;

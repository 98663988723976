import { Services } from "..";
import StaffingImage from "../../assets/images/services/stf-inner.jpeg";

function StaffingPage() {
  const content = (
    <>
      <p>
        We concentrate on client specifications such as what kind of high-quality, highly engaged talent they need to
        turn their business plans into reality.
      </p>

      <p>
        R7 IT solutions Technologies provides you with extremely qualified professionals for small, medium, and large
        organizations, and then we take exceptional care of them so they can concentrate on delivering results for you.
        Whether you’re seeking to extend your inclinations or fill workforce gaps, we can help you meet challenges and
        seize opportunities.
      </p>

      <p>
        We have the insight and networks to hire in-demand applicants across a wide variety of talent sets and
        industries. But more than that, we have a tendency to work among a deliberate method to search out precisely the
        right candidate for your role — not simply a resume that marginally meets your necessities.
      </p>

      <p>
        Our committed staffing specialists work to grasp your business landscape and necessities and partner with you to
        make a solid strategy to attract the proper talent. We have a tendency to additionally offer free competition
        and market intelligence to position you for fulfilment in tight labour markets.
      </p>

      <p>
        Our staffing expertise, focus on client association and our impression allow us to sustain your most complicated
        challenges. Regardless of whether you need direct placement or contract staff, small or large volumes, we work
        with you across a range of staffing support models.
      </p>
    </>
  );
  return <Services image={StaffingImage} title="STAFFING" content={content}></Services>;
}

export default StaffingPage;

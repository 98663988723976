import { PageBanner, Page } from "..";
import BannerImage from "../../assets/images/office.jpeg";

import Artura from "../../assets/images/clients/artura.jpeg";
import Nyc from "../../assets/images/clients/nyc.jpeg";
import Wolters from "../../assets/images/clients/wolters.jpeg";

function Clients() {
  return (
    <Page>
      <section className="page-clients">
        <PageBanner title="Careers" image={BannerImage} />
        <div className="page-clients__body fit-content">
          <div className="section-title">
            <h2>Our Clients</h2>
          </div>
          <p className="page-content">
            Technology changes quickly and impacts every industry. Responding to these changes in a scalable,
            affordable, and effective way can be difficult. Thatâ€™s where R7 IT solutions comes in. We simplify the
            often complicated and time-consuming process of recruiting and screening top IT talent.Â
          </p>

          <div className="client-items flex">
            <div class="client-item">
              <div class="single-logo">
                <img src={Artura} alt="Partner" />
              </div>
            </div>
            <div class="client-item">
              <div class="single-logo">
                <img src={Nyc} alt="Partner" />
              </div>
            </div>
            <div class="client-item">
              <div class="single-logo">
                <img src={Wolters} alt="Partner" />
              </div>
            </div>
            <div class="client-item">
              <div class="single-logo">
                <img src={Nyc} alt="Partner" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}

export default Clients;

import { ArrowRightAlt } from "@mui/icons-material";

function ServicesMenu({ selected }) {
  return (
    <section className="services-menu-list">
      <ul>
        <li className={selected === "STAFFING" ? "active" : ""}>
          <a href="/staffing">
            STAFFING
            <ArrowRightAlt color="inherit" />
          </a>
        </li>
        <li className={selected === "TRAINING" ? "active" : ""}>
          <a href="/training">
            TRAINING <ArrowRightAlt color="inherit" />
          </a>
        </li>
        <li className={selected === "CONSULTING" ? "active" : ""}>
          <a href="/consulting">
            CONSULTING <ArrowRightAlt color="inherit" />
          </a>
        </li>
        <li className={selected === "SOFRWARE DEVELOPMENT" ? "active" : ""}>
          <a href="/development">
            SOFTWARE DEVELOPMENT <ArrowRightAlt color="inherit" />
          </a>
        </li>
        <li className={selected === "ENTERPRISE SOLUTIONS" ? "active" : ""}>
          <a href="/enterprise">
            ENTERPRISE SOLUTIONS <ArrowRightAlt color="inherit" />
          </a>
        </li>
      </ul>
    </section>
  );
}

export default ServicesMenu;

import { Services } from "..";

function ServicesPage() {
  const content = (
    <>
      <p>
        R7 IT Solutions offers comprehensive staffing, recruiting, consulting, IT services that you simply will rely on
        to fill your most crucial desires. We tend to believe business challenges square measure resolved through
        individuals. That’s why we tend to work to know company norms, processes, associated team dynamics and believe
        that cultural work is crucial to the success of an engagement.
      </p>
    </>
  );
  return <Services title="SERVICES" content={content}></Services>;
}

export default ServicesPage;

import { PageBanner, Page } from "..";
import BannerImage from "../../assets/images/office.jpeg";
import Java from "../../assets/images/java.jpeg";
import Net from "../../assets/images/net.jpeg";
import Php from "../../assets/images/php.jpeg";

function TechnologiesPage() {
  return (
    <Page>
      <section className="page-careers">
        <PageBanner title="Technologies" image={BannerImage} />
        <div className="page-careers__body fit-content">
          <div className="technology-item">
            <div className="content">
              <h4>JAVA</h4>
              <p>
                Java development services wrap the implementation of enterprise apps, software products, and SaaS
                solutions using secure, portable, and scalable Java frameworks. A Java application development company
                since 2014, R7 IT solutions helps clients build sustainable Java-based software fast and
                cost-effectively.
              </p>
              <p>
                <b>Java web development</b>
              </p>
              <p>
                Building secured resilient, Elevated-performing applications that provide functionality via the internet
                or intranet.
              </p>
              <p>
                <b>Java cloud development</b>
              </p>
              <p>
                Building cloud-native and serverless apps that efficiently operate cloud attributes (elasticity,
                scalability, etc.) and services.
              </p>
              <p>
                <b>Java-based SaaS application development</b>
              </p>
              <p>
                Building secured SaaS products in rapid quality releases while addressing the varying requirements of
                tenants (compliance, cost, ease of use, etc.).
              </p>
            </div>
            <div className="image">
              <div>
                <img src={Java} alt="Java" />
              </div>
            </div>
          </div>
          <hr className="hr"></hr>
          <div className="technology-item left">
            <div className="content">
              <h4>.NET</h4>
              <p>
                .NET software development services cover engineering and development of web, mobile, and desktop
                applications with the use of the .NET platform.R7 IT solutions has proved to be a .NET development
                company that provides fast software consignment at lowered costs.
              </p>
              <p>
                R7 IT solutions .NET development services scope is incredibly adaptable. We organize an individual
                service set for each client, depending on the project stage, requirements, and collaboration model.
              </p>
              <p>
                <b>Various types of software</b>
              </p>
              <ul>
                <li>Cloud applications.</li>
                <li>Desktop apps.</li>
                <li>Databases.</li>
                <li>APIs.</li>
                <li>Embedded systems.</li>
                <li>IoT apps</li>
                <li>Web apps</li>
                <li>Xamarin</li>
                <li>Сross-platform apps</li>
                <li>SaaS</li>
              </ul>
              <p>
                For all groups of end-users, we facilitate Commercial software products, B2B, B2E, B2C software, Custom
                enterprise platforms, and apps for internal use.
              </p>
            </div>
            <div className="image">
              <div>
                <img src={Net} alt=".Net" />
              </div>
            </div>
          </div>
          <hr className="hr"></hr>
          <div className="technology-item">
            <div className="content">
              <h4>PHP</h4>
              <p>
                PHP development services are aspired to implement secure and strong server sides of all aspects of web
                applications. Drawing on its eight years in PHP development, R7 IT solutions skillfully masters PHP to
                supply powerful effective web portals, B2C &amp; B2B web apps, CMSs, and other web-based solutions.
              </p>
              <p>
                Inclusive of e-commerce, healthcare, banking, manufacturing, logistics, and professional services. This
                approves us to comprehend the specifics of your enterprise and meet your requirements to the fullest.
              </p>
              <p>
                We design software to be strong and stable (with load balancing, system health checks, and failure
                isolation), scalable to be scheduled for numerous nodes working in parallel, and with the responsive
                front end (thanks to the back end optimized with asynchronous calls, microservices, etc.).
              </p>
              <p>
                Such as AI, predictive analytics, and IoT, to convey you a competitive advantage with developed
                software.
              </p>
            </div>
            <div className="image">
              <div>
                <img src={Php} alt="Php" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}

export default TechnologiesPage;
